import { Anchor, Button, Carousel, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import Image from "../../../../Components/Image";
import REQUEST from "../../../../Service/Request.js";

const { Link } = Anchor;

const AppHero = ({ t, i18n = {} }) => {
  const data = [
    {
      ImagePath:
        "https://images.unsplash.com/photo-1574755297613-7b2c5fee60ce?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      Title: "here",
      Description: "here1",
    },
    {
      ImagePath:
        "https://images.unsplash.com/photo-1449247666642-264389f5f5b1?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      Title: "here1",
      Description: "here1",
    },
    {
      ImagePath:
        "https://images.unsplash.com/photo-1491933382434-500287f9b54b?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      Title: "here1",
      Description: "here",
    },
  ];

  const [dataa, setDataa] = useState();

  let config = {
    method: "GET",
    url: "api/",
  };

  REQUEST(config)
    .then((response) => setDataa(response))
    .catch((error) => console.log(error));

  return (
    <>
      <div className="heroBlock  ">
        <OwlCarousel
          //stageclassName={"container owl-stage-outerx"}

          items={1}
          autoplay={true}
          className="owl-theme"
          loop
          margin={20}
          nav
          navText={[
            "<i class='fas fa-angle-left'></i>",
            "<i class='fas fa-angle-right'></i>",
          ]}
        >
          {data.map((ele, index) => (
            <div key={index} style={{ maxHeight: "500px" }}>
              <Image src={ele.ImagePath} />
            </div>
          ))}
        </OwlCarousel>
      </div>
    </>
  );
};

export default React.memo(AppHero);
