import React from "react";
import { useTranslation } from "react-i18next";
import OwlCarousel from "react-owl-carousel";
import ProductCard from "../../../../Components/ProductCard";
import TitleHeader from "../../../../Components/TitleHeader";

function HorizontalSlider() {
  const data = [
    {
      image:
        "https://images.unsplash.com/photo-1494438639946-1ebd1d20bf85?q=80&w=2067&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Apple (iTunes ) USA",
      price: 1234,
    },
    {
      image:
        "https://plus.unsplash.com/premium_photo-1677541205130-51e60e937318?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fHByb2R1Y3R8ZW58MHx8MHx8fDA%3D",
      title: "Apple (iTunes ) USA",
      price: 1234,
    },
    {
      image:
        "https://images.unsplash.com/photo-1543163521-1bf539c55dd2?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Apple (iTunes ) USA",
      price: 1234,
    },
    {
      image:
        "https://images.unsplash.com/photo-1560769629-975ec94e6a86?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Apple (iTunes ) USA",
      price: 1234,
    },

    {
      image:
        "https://images.unsplash.com/photo-1560769629-975ec94e6a86?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Apple (iTunes ) USA",
      price: 1234,
    },
    {
      image:
        "https://images.unsplash.com/photo-1553062407-98eeb64c6a62?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Apple (iTunes ) USA",
      price: 1234,
    },
  ];
  const { t, i18n } = useTranslation();
  return (
    <div className="hor-slide">
      <div>
        <TitleHeader title={t("New")} details={"Item"} shaded={"Trendy"} />
      </div>

      <OwlCarousel
        //stageclassName={"container owl-stage-outerx"}

        items={5}
        //autoplay={true}   loop
        className="owl-theme"
        margin={20}
        height={"100%"}
        nav
        navText={[
          "<i class='fas fa-angle-left'></i>",
          "<i class='fas fa-angle-right'></i>",
        ]}
        responsive={{
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
        }}
      >
        {data.map((ele, index) => (
          <div key={index}>
            <ProductCard style={{ margin: "35px 0" }} ele={ele} />
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
}

export default HorizontalSlider;
